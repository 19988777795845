<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-toolbar
        flat
      >
        <v-toolbar-title>Staff List</v-toolbar-title>
        <v-divider
          class="mx-2"
          inset
          vertical
        />
        <v-spacer />
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template #activator="{ on }">
            <v-btn
              :disabled="disabled"
              color="primary"
              dark
              class="mb-2"
              v-on="on"
            >
              Add Staff
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editedItem.name"
                      label="Name"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editedItem.msisdn"
                      label="Msisdn"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editedItem.topup"
                      label="Topup"
                      prefix="RM"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                flat
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                flat
                @click="save"
              >
                {{ formTitle }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-alert
        :value="true"
        :type="alertType"
      >
        {{ alertMessage }}
      </v-alert>
      <v-data-table
        :headers="headers"
        :items="staffs"
        class="elevation-1"
      >
        <template #items="props">
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-left">
            {{ props.item.name }}
          </td>
          <td class="text-xs-right">
            {{ props.item.topup }}
          </td>
          <td class="justify-center layout px-0">
            <v-icon
              small
              class="mr-2"
              @click="editItem(props.item)"
            >
              edit
            </v-icon>
            <v-icon
              small
              @click="deleteItem(props.item)"
            >
              delete
            </v-icon>
          </td>
        </template>
        <template #footer>
          <tr>
            <td
              class="text-xs-right"
              colspan="2"
            >
              Total:
            </td>
            <td class="text-xs-right">
              {{ totalTopup }}
            </td>
            <td />
          </tr>
        </template>
      </v-data-table>
      <v-dialog
        v-model="executeDialog"
        max-width="500px"
      >
        <template #activator="{ on }">
          <v-btn
            :disabled="disabled"
            color="primary"
            dark
            class="mb-2"
            v-on="on"
          >
            Execute Topup
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Execute Topup</span>
          </v-card-title>

          <v-alert
            :value="true"
            :type="executeAlertType"
            v-html="executeAlertMessage"
          />

          <v-card-actions>
            <v-spacer />
            <v-btn
              :disabled="loading"
              color="blue darken-1"
              flat
              @click="executeDialog = false"
            >
              {{ executeAlertType === 'info' ? 'Cancel' : 'Close' }}
            </v-btn>
            <v-btn
              v-show="executeAlertType === 'info'"
              :loading="loading"
              :disabled="loading"
              color="blue darken-1"
              flat
              @click="executeTopup"
            >
              Execute
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </panel>
  </v-scale-transition>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams, createPostParams } from '@/rest'

const pageTitle = 'Staff Topup Benefit'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data: () => ({
    title: pageTitle,
    alertType: 'info',
    alertMessage: 'Last topup: NEVER',
    executeAlertType: 'info',
    executeAlertMessage: null,
    dialog: false,
    executeDialog: false,
    loading: false,
    lastTopup: '',
    breadcrumbs: [
      {
        text: 'Operation Kits', disabled: false, to: '/operation/operation_kits',
      },
      {
        text: pageTitle, disabled: true,
      },
    ],
    headers: [
      {
        text: 'Msisdn',
        align: 'left',
        sortable: true,
        value: 'msisdn',
      },
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Topup',
        align: 'right',
        sortable: true,
        value: 'topup',
      },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        value: 'msisdn',
      },
    ],
    staffs: [],
    editedIndex: -1,
    editedItem: {
      msisdn: null,
      name: null,
      topup: 0,
    },
    defaultItem: {
      msisdn: null,
      name: null,
      topup: 0,
    },
  }),

  created: function () {
    this.loadStaffTopup()
  },

  computed: {
    totalTopup () {
      let totalTopup = 0
      this.staffs.forEach(staff => {
        totalTopup += staff.topup * 1
      })

      return totalTopup
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Add Staff' : 'Edit Staff'
    },

    disabled () {
      const thisMonth = DateTime.local().setZone('Asia/Kuala_Lumpur').toFormat('yyyy-LL')
      return this.lastTopup.substring(0, 7) >= thisMonth
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    executeDialog (val) {
      if (val) {
        this.executeAlertType = 'info'
        this.executeAlertMessage = '<div>Click <strong>EXECUTE</strong> only when you are sure that the MSISDN list and topup amount are correct.</div>'
      }
    },
  },

  methods: {
    async executeTopup () {
      const params = createPostParams({
        action: 'staffTopup',
        payload: this.staffs,
      })

      try {
        this.loading = true
        await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.executeAlertType = 'success'
          this.executeAlertMessage = 'Your request to execute topup for staffs was successful.'
          this.loadStaffTopup()
        })
      } catch (e) {
        this.$nextTick(() => {
          this.executeAlertType = 'error'
          this.executeAlertMessage = e.message + ': ' + e.response.data
        })
      } finally {
        this.loading = false
      }
    },

    loadStaffTopup () {
      const params = createGetParams({})
      let lastTopup = ''

      this.$rest.get('loadStaffTopup.php', params)
        .then((response) => {
          response.data.forEach(staff => {
            if (staff.lastTopup > lastTopup) {
              lastTopup = staff.lastTopup
            }
          })
          this.alertMessage = 'Last topup execution: ' + lastTopup
          this.lastTopup = lastTopup
          this.staffs = response.data
        }).catch(error => {
          console.log(error)
        })
    },

    editItem (item) {
      this.editedIndex = this.staffs.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      const index = this.staffs.indexOf(item)
      confirm('Are you sure you want to delete this item?') && this.staffs.splice(index, 1)
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.staffs[this.editedIndex], this.editedItem)
      } else {
        this.staffs.push(this.editedItem)
      }
      this.close()
    },
  },
}
</script>

<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
